import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { MenuPage } from './menu/menu.page';

const routes: Routes = [
  {
    path: '',
    //component: MenuPage,
    children: [
      //{ path: 'home', loadChildren: './home/home.module#HomePageModule' },
      //{ path: 'search', loadChildren: './search/search.module#SearchPageModule' },
      //{ path: 'about', loadChildren: './about/about.module#AboutPageModule' },
      { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' }
      //{ path: '', redirectTo: '/home', pathMatch: 'full' },
    ],
  },  { path: 'modal-page', loadChildren: './pages/modal-page/modal-page.module#ModalPagePageModule' },


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
