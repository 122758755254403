import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.page.html',
  styleUrls: ['./modal-page.page.scss'],
})
export class ModalPagePage implements OnInit {

  ngOnInit() {}

  @Input() value: number;

   constructor(private navParams: NavParams, private modalController: ModalController) {
        console.log(JSON.stringify(navParams.get('value')));
   }


  dismissModal() {
     this.modalController.dismiss();
  }

}
